<template>
  <v-container class="pa-6" fluid>
    <h2 class="sidebar-title mt-2 mb-6">{{ this.pageName }}</h2>
    <v-row>
      <v-col cols="3">
        <h2 class="sidebar-title sidebar-title-small mb-6">Restaurant groups</h2>
        <v-text-field v-if="getUserData.roleNames[0].toLowerCase() === 'admin'" @input="handleAreaSearch" solo
          label="Find restaurant group" class="standart-input mt-3" flat prepend-inner-icon="mdi-magnify"
          type="text"></v-text-field>
        <GroupTab @tabClick="tabClick" :list="areas" :isArea="false" :activeTabId="activeAreaId" :showDots="false" />
      </v-col>
      <v-col>
        <div class="standart-card">
          <v-row>
            <v-col class="col-lg-10 col-9">
              <v-text-field @input="handleSearch" solo :label="`Find ${this.pageNameSingular}`" class="standart-input"
                flat prepend-inner-icon="mdi-magnify" type="text"></v-text-field>
            </v-col>
            <v-col>
              <router-link :to="isLoading ? '#' : `/${this.pageNameSingular}/new`"
                class="standart-button standart-button-secondary">New {{ this.pageNameSingular }}</router-link>
            </v-col>
          </v-row>
          <EntityList :isLoading="isEntitiesLoading" :list="entityList" :isSearched="isSearched" :isShowEmail="true"
            :detailUrl="this.pageNameSingular" :groupId="this.getActiveAreaId" @handleDelete="deleteEntityItem" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { debounce } from 'vue-debounce';
import GroupTab from '../components/GroupTab.vue';
import EntityList from '../components/EntityList.vue';
import { TOAST_TYPES } from '@/data';

export default {
  name: 'Dealers',
  components: {
    GroupTab,
    EntityList,
  },
  data() {
    return {
      searchValue: '',
      areaSearchValue: '',
      venueGroupSearchValue: '',
      pageName: 'dealers',
      pageNameSingular: 'dealer',
    };
  },
  computed: {
    ...mapGetters({
      areas: 'areas/getList',
      entityList: 'dealers/getList',
      isEntitiesLoading: 'dealers/isLoading',
      getActiveAreaId: 'dealers/getActiveAreaId',
      getSearch: 'dealers/getSearch',
      isLoading: 'dealers/isLoading',
      getUserData: 'app/getUserData',
    }),
    isSearched() {
      return Boolean(this.searchValue.length);
    },
    activeAreaId() {
      return this.getActiveAreaId;
    },
  },
  created() {
    this.getAreasData()
  },
  mounted() {
    this.setDealersList([]);
    if (this.getSearch) {
      this.fetchEntitiesById(this.getActiveAreaId);
      this.clearSearch();
    }
  },
  methods: {
    ...mapActions({
      fetchEntities: 'dealers/fetchList',
      searchEntities: 'dealers/search',
      searchArea: 'areas/search',
      deleteEntity: 'dealers/deleteItem',
      fetchAreas: 'areas/fetchData',
    }),
    ...mapMutations({
      setActiveAreaId: 'dealers/setActiveAreaId',
      setSearch: 'dealers/setSearch',
      setAreaSearch: 'areas/setSearch',
      clearSearch: 'dealers/clearSearch',
      setDealersList: 'dealers/setList',
      setAreasList: 'areas/setList',
    }),
    getAreasData() {
      this.fetchAreas()
    },
    tabClick(id) {
      this.setActiveAreaId(id);
      this.fetchEntitiesById(id);
    },
    fetchEntitiesById(id) {
      this.fetchEntities(`/${this.pageName}?venueGroupId=${id}`)
    },
    deleteEntityItem(id) {
      this.deleteEntity(id).then(data => {
        if (data) {
          this.$toast.open({ message: 'Dealer has been successfully deleted!', type: TOAST_TYPES.SUCCESS });
        }
      });
    },
    handleSearch: debounce(function (searchQuery) {
      this.searchValue = searchQuery;
      this.setSearch(searchQuery);
      if (this.getActiveAreaId) {
        this.searchEntities(`/${this.pageName}?venueGroupId=${this.getActiveAreaId}&partOfUserName=${searchQuery}`)
      }
      else {
        this.searchEntities(`/${this.pageName}?partOfUserName=${searchQuery}`)
      }
    }, '1000ms'),
    handleAreaSearch: debounce(function (searchQuery) {
      this.isArea = true
      this.areaSearchValue = searchQuery;
      this.setAreaSearch(searchQuery);
      const url = `/areas?partOfName=${searchQuery}`;
      this.searchArea(url).then((data) => {
        this.setAreasList(data)
      })
    }, '1000ms'),
  },
};
</script>

<style lang="scss"></style>
